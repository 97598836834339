<template>
  <div>
    <b-card>
      <title-text />
      <content-text />
      <url-text />
      <ordering-text />
      <status-select />
    </b-card>
    <image-card />
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Links/elements/TitleText.vue'
import ContentText from '@/views/Admin/Links/elements/ContentText.vue'
import UrlText from '@/views/Admin/Links/elements/UrlText.vue'
import OrderingText from '@/views/Admin/Links/elements/OrderingText.vue'
import StatusSelect from '@/views/Admin/Links/elements/StatusSelect.vue'
import ImageCard from '@/views/Admin/Links/elements/ImageCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleText,
    ContentText,
    UrlText,
    OrderingText,
    StatusSelect,
    ImageCard,
  },
}
</script>
